import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.jpg'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import twitter from '../assets/twitter.png'
import facebook from '../assets/facebook.png'


const items = [
    {
        "title": "Facebook",
        "subtitle": "@thenomadmarc ",
        "image": facebook,
        "link": "https://www.facebook.com/thenomadmarc/" //Telegram Profile 
    },
{
    "title": "Instagram",
    "subtitle": "@thenomadmarc",
    "image": instagram,
    "link": "https://www.instagram.com/thenomadmarc" //instagram profile link 
},
{
    "title": "Twitter",
    "subtitle": "@thenomadmarc",
    "image": twitter,
    "link": "https://twitter.com/thenomadmarc"// twitter profile link 
},
{
    "title": "LinkedIn",
    "subtitle": "Marc Sommer",
    "image": linkedin,
    "link": "https://www.linkedin.com/in/marc-sommer" // linkedin
},
{
    "title": "Telegram",
    "subtitle": "@thenomadmarc ",
    "image": telegram,
    "link": "https://telegram.me/thenomadmarc" //Telegram Pofile 
},
{
    "title": "GitHub",
    "subtitle": "@marc-sommer",
    "image": github,
    "link": "https://github.com/marc-sommer" //Github Profile link
}
]


export default items
